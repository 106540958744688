import { Button, ButtonGroup, useDisclosure } from '@chakra-ui/react';
import { ListView } from 'components/@common';
import { Modal } from 'components/@common/layout';
import React from 'react';
import { useStore } from 'store';
import { MaterialSupplier } from '../../models/material';
import { SupplierModal } from './supplier-modal';

export function SupplierList(props: { limit: number }) {
  const [store, dispatch] = useStore();
  const [page, setPage] = React.useState(0);
  const [failedFetch, setFetchFailed] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const listState = store.resources.suppliers;

  const fetchItems = React.useCallback(
    (forceRefresh: boolean = false) => {
      dispatch('resources', 'fetchResources', { resource: 'suppliers', page, limit: props.limit, forceRefresh }, undefined, () =>
        setFetchFailed(true),
      );
    },
    [props.limit, page],
  );

  React.useEffect(() => {
    fetchItems();
  }, [page]);

  return (
    <>
      <ListView<MaterialSupplier>
        title='Supplier list'
        dataId='id'
        items={listState.items}
        totalItems={listState.totalItems}
        page={listState.page}
        limit={listState.limit}
        isLoading={listState.isLoading}
        headerVisible
        headerActions={[
          {
            label: 'Create supplier',
            colorScheme: 'blue',
            onClick: onOpen,
          },
        ]}
        onChangePage={setPage}
        onRefresh={fetchItems.bind(null, true)}
        onSort={(key, val) => {}}
        emptyMessage={'No suppliers defined'}
        errorMessage={failedFetch ? 'Error loading data' : undefined}
        columns={[
          {
            key: '@action',
            title: 'actions',
            render: (data, item) => <ActionButtons {...item} onChange={fetchItems.bind(null, true)} />,
            props: {
              width: '200px',
            },
          },
          {
            key: 'name',
            title: 'Supplier name',
          },
          {
            key: 'email',
            title: 'Contact',
          },
        ]}
      />
      <SupplierModal
        isOpen={isOpen}
        onCancel={onClose}
        onSubmitted={() => {
          onClose();
          fetchItems(true);
        }}
      />
    </>
  );
}

function ActionButtons(
  props: MaterialSupplier & {
    onChange: () => void;
  },
) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ButtonGroup>
        <Button size='sm' variant='outline' colorScheme='blue' onClick={onOpen}>
          Edit
        </Button>
      </ButtonGroup>
      <Modal title={`Update '${props.name}'`} isOpen={isOpen} onClose={onClose}>
        <SupplierModal
          supplier={props}
          isOpen={isOpen}
          onCancel={onClose}
          onSubmitted={() => {
            onClose();
            props.onChange();
          }}
        />
      </Modal>
    </>
  );
}
