import { useStore } from '@app:store';
import { Button, Flex, Input, Select, Stack } from '@chakra-ui/react';
import { BoxLoader } from 'components/@common/layout';
import { ProductTemplate } from 'models/templates';
import React from 'react';
import { RiDeleteBin2Line } from 'react-icons/ri';

export type ProductInputValue = Record<string, string | number>;

export type ProductInputSchema = {
  recipe: string;
  productNumber: string;
  qrAccess: string;
  perfumeName: string;
  author: string;
};

export function parseProductNumber(index: number) {
  return `XXX-${(index + 1).toString().padStart(2, '0')}`;
}

export function ProductsInput(props: { value: ProductInputValue[]; onChange: (value: ProductInputValue[]) => void }) {
  const [store, dispatch] = useStore();
  const [failedFetch, setFetchFailed] = React.useState(false);

  React.useEffect(() => {
    dispatch('resources', 'fetchResources', { resource: 'templates', page: 0, limit: 50 }, undefined, () => setFetchFailed(true));
  }, []);

  const handleChange = (index: number, value: ProductInputValue) => {
    const newValue = [...props.value];
    newValue[index] = value;
    props.onChange(newValue);
  };

  const handleDelete = (index: number) => {
    if (props.value.length > 1) {
      const newValue = [...props.value];
      newValue.splice(index, 1);
      props.onChange(
        newValue.map((p, i) => ({
          ...p,
          productNumber: parseProductNumber(i),
        })),
      );
    }
  };

  return (
    <BoxLoader>
      <Stack>
        {props.value.map((val, index) => (
          <OrderProductsInputRow
            key={index}
            value={val}
            templateOptions={store.resources.templates.items}
            onChange={handleChange.bind(null, index)}
            onDelete={handleDelete.bind(null, index)}
          />
        ))}
        <Button
          onClick={() => {
            const newValue = [...props.value];
            newValue.push({
              productNumber: parseProductNumber(newValue.length),
            });
            props.onChange(newValue);
          }}
        >
          + Add product
        </Button>
      </Stack>
    </BoxLoader>
  );
}

function OrderProductsInputRow(props: {
  templateOptions: ProductTemplate[];
  value: ProductInputValue;
  onChange: (value: ProductInputValue) => void;
  onDelete: () => void;
}) {
  const [templateId, setTemplateId] = React.useState(props.templateOptions.length > 0 ? props.templateOptions[0].id : undefined);
  const customAttributes = React.useMemo<{ key: string; label: string }[]>(() => {
    if (!templateId) return [];
    else {
      const value = props.templateOptions.find((template) => template.id === templateId)?.customizationAttributes;
      if (!value) return [];
      return Object.entries(value).map(([key, value]) => ({
        key,
        label: value.label,
      }));
    }
  }, [templateId]);

  React.useEffect(() => {
    setTemplateId((val) => {
      if (props.templateOptions.length === 0) return undefined;
      if (val !== undefined) return val;
      return props.templateOptions[0].id;
    });
  }, [props.templateOptions]);

  React.useEffect(() => {
    if (customAttributes.length === 0) return;
    const newValue = JSON.parse(JSON.stringify(props.value)) as ProductInputValue;
    customAttributes.forEach(({ key }) => {
      newValue[key] = '';
    });
    props.onChange(newValue);
  }, [customAttributes]);

  const handleChange = (key: string, event: any) => {
    const newValue = JSON.parse(JSON.stringify(props.value)) as ProductInputValue;
    newValue[key] = event.target.value;
    props.onChange(newValue);
  };

  return (
    <Flex gap={3} alignItems={'center'}>
      <Select w='200px' value={templateId} onChange={(e) => setTemplateId(e.target.value)}>
        {props.templateOptions.map((template) => {
          return (
            <option key={template.id} value={template.id}>
              {template.label}
            </option>
          );
        })}
      </Select>
      {customAttributes.length > 0 ? (
        customAttributes.map(({ key, label }) => {
          return <Input key={key} flex={1} value={props.value[key] || ''} placeholder={label} onChange={handleChange.bind(null, key)} />;
        })
      ) : (
        <Input flex={1} isDisabled readOnly value='No attributes required.' />
      )}
      <Button variant='outline' colorScheme='red' p={0} size='sm' onClick={props.onDelete}>
        <RiDeleteBin2Line />
      </Button>
    </Flex>
  );
}
