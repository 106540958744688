import { IQuantity, InnerProductUnits } from '@ellure/types';

import { useFormik } from 'formik';
import { useStore } from 'store';

import { useToast } from '@chakra-ui/react';
import { Form, Modal, QuantityInput, SupplierSelectInput } from 'components';
import React from 'react';

export type BatchInputSchema = {
  batchNumber: string;
  supplierId: string;
  expirationDate: string;
  quantity: IQuantity<InnerProductUnits>;
};

export function MaterialBatchModal(props: { onCancel: () => void; onSubmitted: () => void; materialId: string; isOpen: boolean }) {
  const [store, dispatch] = useStore();

  const toast = useToast();
  const form = useFormik<BatchInputSchema>({
    initialValues: {
      batchNumber: '',
      supplierId: '',
      expirationDate: new Date().toISOString().split('T')[0],
      quantity: { amount: 0, unit: InnerProductUnits.GRAM },
    },
    onSubmit: (data, helpers) => {
      const onSuccess = () => {
        props.onSubmitted();
        toast({
          title: 'Batch created.',
          description: 'Your action succeeded',
          status: 'success',
          duration: 2500,
          isClosable: true,
        });
      };

      const onFailed = () => {
        helpers.setSubmitting(false);
      };

      dispatch(
        'resources',
        'createBatch',
        {
          materialId: props.materialId,
          data,
        },
        onSuccess,
        onFailed,
      );
    },
  });

  const materialName = React.useMemo(() => {
    return store.resources.materials.items.find((item) => item.id === props.materialId)?.label || 'Unknown';
  }, [props.materialId]);

  return (
    <Modal
      title={`Create new material batch for '${materialName}'`}
      isOpen={props.isOpen}
      onClose={props.onCancel}
      modalContentStyle={{ maxW: '800px', w: '90%' }}
    >
      <Form<BatchInputSchema>
        {...form}
        submitLabel='Update'
        schema={[
          {
            label: 'Batch number',
            name: 'batchNumber',
          },
          {
            label: 'Quantity',
            name: 'quantity',
            render: (value, { handleChange }) => (
              <QuantityInput<InnerProductUnits> value={value as IQuantity<InnerProductUnits>} onChange={handleChange} />
            ),
          },
          {
            label: 'Expiration Date',
            name: 'expirationDate',
            props: {
              type: 'date',
            },
          },
          {
            label: 'Supplier',
            name: 'supplierId',
            render: (value, { handleChange }) => <SupplierSelectInput value={value as string} onChange={handleChange} />,
          },
        ]}
      />
    </Modal>
  );
}
