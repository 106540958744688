import CollectorSettings from './collector-settings';

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { SimplePageTemplate } from 'components/@common/layout';

import { RiSettings2Line } from 'react-icons/ri';

const Content = () => {
  return (
    <Tabs variant='enclosed' width='100%' height='100%' display='flex' flexDir='column'>
      <TabList>
        <Tab _selected={{ bg: 'white', borderColor: 'gray.200', borderBottom: 0 }}>Collection</Tab>
        <Tab _selected={{ bg: 'white', borderColor: 'gray.200', borderBottom: 0 }}>Account</Tab>
      </TabList>

      <TabPanels bg='#fcfcfc' borderColor='gray.200' borderWidth='1px' flex={1}>
        <TabPanel height='100%'>
          <CollectorSettings />
        </TabPanel>
        <TabPanel height='100%'></TabPanel>
      </TabPanels>
    </Tabs>
  );
};

const page = () => {
  return (
    <SimplePageTemplate
      {...{
        title: 'Settings',
        icon: RiSettings2Line,
      }}
      content={<Content />}
    />
  );
};

export default page;
