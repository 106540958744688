import ReactDOM from 'react-dom/client';

import { initializeApi } from '@ellure/api-client-admin';
import { initializeApp } from 'firebase/app';

import { useStickyState } from 'utils/hooks';

import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import StoreProvider from 'store';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { StoreState } from 'store/store';
import theme from './@theme/theme';

const useFirebaseEmulation = process.env.REACT_APP_FIREBASE_EMULATE === 'true';

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: useFirebaseEmulation ? 'localhost' : process.env.REACT_APP_FIREBASE_AUTH_HOST,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

if (useFirebaseEmulation) {
  const auth = getAuth(app);
  connectAuthEmulator(auth, process.env.REACT_APP_FIREBASE_AUTH_HOST || 'http://localhost:25008');
}

initializeApi({
  baseURL: process.env.REACT_APP_API_HOST as string,
});

const storeVersion = '0.1.0';
const Root = () => {
  const [state, setState] = useStickyState<StoreState>(undefined!, `adminStore_${storeVersion}`);
  return (
    <StoreProvider savedState={state} onSave={setState}>
      <ChakraProvider theme={theme}>
        <Router>
          <App />
        </Router>
      </ChakraProvider>
    </StoreProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<Root />);
reportWebVitals();
