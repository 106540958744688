import { Center, Spinner, StackDivider, VStack } from '@chakra-ui/react';
import { ProductionLine } from 'components';
import { SimplePageTemplate } from 'components/@common/layout';
import React from 'react';
import { RiLineChartLine } from 'react-icons/ri';
import { useStore } from 'store';

const Content = () => {
  const [store, dispatch] = useStore();

  React.useEffect(() => {
    dispatch('resources', 'fetchResources', { resource: 'materials', page: 0, limit: 50 });
  }, []);

  return (
    <VStack w='100%' h='100%' spacing={3} divider={<StackDivider />} overflowX={'auto'}>
      {store.location.productionLines.length > 0 ? (
        store.location.productionLines.map((line) => <ProductionLine {...line} key={line.id} />)
      ) : (
        <Center h='100%'>
          <Spinner size='lg' />
        </Center>
      )}
    </VStack>
  );
};

export const Production = () => {
  return (
    <SimplePageTemplate
      {...{
        title: 'Production timeline',
        icon: RiLineChartLine,
      }}
      content={<Content />}
    />
  );
};
