import React from 'react';

import { useStore } from '@app:store';
import { Select } from '@chakra-ui/react';
import { BoxLoader } from 'components/@common/layout';

export function SupplierSelectInput(props: { value: string; onChange: (val: any) => void }) {
  const [store, dispatch] = useStore();

  React.useEffect(() => {
    dispatch('resources', 'fetchResources', { resource: 'suppliers', page: 0, limit: 40 });
  }, []);

  const handleChange = (event: any) => {
    props.onChange(event.target.value);
  };

  return (
    <BoxLoader isLoading={store.resources.suppliers.isLoading}>
      <Select onChange={handleChange} value={props.value}>
        <option value=''>--SELECT A SUPPLIER--</option>
        {store.resources.suppliers.items.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </Select>
    </BoxLoader>
  );
}
