import { WebsocketAction } from '@app:services';
import { ActionContext } from '@app:types';
import { UseToastIntercept } from 'store';
import { StoreState } from 'store/store';
import { ProductionLocationStateDto, ServerActions } from '../../models/production-location';
import { LocationMutations } from './locations.reducer';

const actions: ActionContext<StoreState, LocationMutations> = {
  async set({ mutate }, payload: { id: string; name?: string }) {
    mutate(LocationMutations.SET_LOCATION, payload);
  },

  async processMessage(injectee, action: WebsocketAction<ServerActions>) {
    switch (action.type) {
      case 'location.update':
        await this.updateState(injectee, action.payload);
        break;
      case 'location.notify':
        throw new UseToastIntercept({
          duration: 3000,
          isClosable: true,
          title: action.payload.action,
          description: action.payload.description,
          status: action.payload.type || 'info',
        });
    }
  },

  async updateState({ mutate }, payload: ProductionLocationStateDto) {
    payload.productionLines.forEach((line) => mutate(LocationMutations.SET_PRODUCTION_LINE, line));
  },
};

export default actions;
