import { PendingProductInventory, ReducerContext, SortingModes } from '@app:types';
import { Order, PendingOrder as PendingOrderDto } from '@ellure/types';
import { OrdersState } from './orders.state';

export enum OrderMutations {
  START_LOADING = 'set-loading',
  STOP_LOADING = 'unset-loading',
  SET_ORDERS = 'set-order',
  SET_SORTING = 'set-sorting',
}

const reducers: ReducerContext<OrdersState> = {
  [OrderMutations.START_LOADING]: function (state, payload: 'pending' | 'archived') {
    state[payload].isLoading = true;
  },
  [OrderMutations.STOP_LOADING]: function (state, payload: 'pending' | 'archived') {
    state[payload].isLoading = false;
  },
  [OrderMutations.SET_SORTING]: function (
    state,
    payload: {
      itemSet: 'pending' | 'archived';
      key: keyof OrdersState['pending']['sortedBy'];
      sortMode: SortingModes;
    },
  ) {
    if (!(payload.key in state[payload.itemSet].sortedBy)) return;
    state[payload.itemSet].sortedBy[payload.key] = payload.sortMode;
  },
  [OrderMutations.SET_ORDERS]: function (
    state,
    payload:
      | {
          itemSet: 'pending';
          items: PendingOrderDto[];
          page: number;
          limit: number;
          totalItems: number;
        }
      | {
          itemSet: 'archived';
          items: Order[];
          page: number;
          limit: number;
          totalItems: number;
        },
  ) {
    if (payload.itemSet === 'pending') {
      state[payload.itemSet].items = payload.items.map(({ content, ...item }) => ({
        ...item,
        products: content.reduce(
          (map, p) => {
            if (p._status !== 'fulfilled') {
              if (p._status === 'completed') map['completed'].push(p);
              else if (p._status === 'requested') map['requested'].push(p);
              else {
                if ((p.metadata as any).processedAs === 'ghost') map['unprocessed'].push(p);
                else if ((p.metadata as any).processedAs === 'automatic') map['automatic'].push(p);
                else map['manual'].push(p);
              }
            }

            return map;
          },
          {
            automatic: [],
            manual: [],
            unprocessed: [],
            requested: [],
            completed: [],
          } as PendingProductInventory,
        ),
      }));
    } else {
      state[payload.itemSet].items = payload.items;
    }
    state[payload.itemSet].page = payload.page;
    state[payload.itemSet].limit = payload.limit;
    state[payload.itemSet].totalItems = payload.totalItems;
    state[payload.itemSet].lastUpdated = new Date().toISOString();
  },
};

export default reducers;
