import { ReducerContext } from '@app:types';
import { Material, MaterialSupplier } from '../../models/material';
import { ProductTemplate } from '../../models/templates';
import { ResourcesState } from './resources.state';

export enum ResourceMutations {
  START_LOADING = 'set-loading',
  STOP_LOADING = 'unset-loading',

  SET_RESOURCE_ITEMS = 'set-resource-items',
}

const reducers: ReducerContext<ResourcesState> = {
  [ResourceMutations.START_LOADING]: function (state, payload: 'templates' | 'materials' | 'suppliers') {
    state[payload].isLoading = true;
  },
  [ResourceMutations.STOP_LOADING]: function (state, payload: 'templates' | 'materials' | 'suppliers') {
    state[payload].isLoading = false;
  },
  [ResourceMutations.SET_RESOURCE_ITEMS]: function (
    state,
    payload:
      | {
          itemSet: 'templates';
          items: ProductTemplate[];
          page: number;
          limit: number;
          totalItems: number;
        }
      | {
          itemSet: 'materials';
          items: Material[];
          page: number;
          limit: number;
          totalItems: number;
        }
      | {
          itemSet: 'suppliers';
          items: MaterialSupplier[];
          page: number;
          limit: number;
          totalItems: number;
        },
  ) {
    state[payload.itemSet].items = payload.items;
    state[payload.itemSet].page = payload.page;
    state[payload.itemSet].limit = payload.limit;
    state[payload.itemSet].totalItems = payload.totalItems;
    state[payload.itemSet].lastUpdated = new Date().toISOString();
  },
};

export default reducers;
