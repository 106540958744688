import { Slice } from '@app:types';
import { StoreState } from 'store/store';
import actions from './resources.actions';
import reducers, { ResourceMutations } from './resources.reducer';
import { ResourcesState, initialState } from './resources.state';

const slice: Slice<StoreState, ResourcesState, ResourceMutations> = {
  name: 'resources',
  state: initialState,
  reducers,
  actions,
};

export default slice;
