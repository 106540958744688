import { Button, ButtonGroup, useDisclosure } from '@chakra-ui/react';
import { ListView } from 'components/@common';
import React from 'react';
import { Link } from 'react-router-dom';
import { useStore } from 'store';
import { Material } from '../../models/material';
import { MaterialBatchModal } from './material-batch-modal';
import { MaterialModal } from './material-modal';

export function MaterialList(props: { limit: number }) {
  const [store, dispatch] = useStore();
  const [page, setPage] = React.useState(0);
  const [failedFetch, setFetchFailed] = React.useState(false);

  const { isOpen: showCreateMaterialModal, onOpen: openCreateMaterialModal, onClose: closeCreateMaterialModal } = useDisclosure();
  const { isOpen: showCreateBatchModal, onOpen: openCreateBatchModal, onClose: closeCreateBatchModal } = useDisclosure();
  const [selectedMaterialId, setSelectedMaterialId] = React.useState<string>('');

  const listState = store.resources.materials;

  const fetchItems = React.useCallback(
    (forceRefresh: boolean = false) => {
      dispatch('resources', 'fetchResources', { resource: 'materials', page, limit: props.limit, forceRefresh }, undefined, () =>
        setFetchFailed(true),
      );
    },
    [props.limit, page],
  );

  React.useEffect(() => {
    fetchItems();
  }, [page]);

  return (
    <>
      <ListView<Material>
        title='Material list'
        dataId='id'
        items={listState.items}
        totalItems={listState.totalItems}
        page={listState.page}
        limit={listState.limit}
        isLoading={listState.isLoading}
        headerVisible
        headerActions={[
          {
            label: 'Create material',
            colorScheme: 'blue',
            onClick: openCreateMaterialModal,
          },
        ]}
        onChangePage={setPage}
        onRefresh={fetchItems.bind(true)}
        onSort={(key, val) => {}}
        emptyMessage={'No suppliers defined'}
        errorMessage={failedFetch ? 'Error loading data' : undefined}
        columns={[
          {
            key: '@action',
            title: 'actions',
            render: (data, item) => (
              <ButtonGroup>
                <Link to={item.id}>
                  <Button size='sm' variant='outline' colorScheme='blue'>
                    View
                  </Button>
                </Link>
                <Button
                  size='sm'
                  variant='outline'
                  onClick={() => {
                    setSelectedMaterialId(item.id);
                    openCreateBatchModal();
                  }}
                >
                  +Batch
                </Button>
              </ButtonGroup>
            ),
            props: {
              width: '200px',
            },
          },
          {
            key: 'type',
            title: 'type',
          },
          {
            key: 'displayName',
            title: 'Material name',
          },
        ]}
      />
      <MaterialModal
        isOpen={showCreateMaterialModal}
        onCancel={closeCreateMaterialModal}
        onSubmitted={() => {
          closeCreateMaterialModal();
          fetchItems(true);
        }}
      />
      <MaterialBatchModal
        materialId={selectedMaterialId}
        isOpen={showCreateBatchModal}
        onCancel={closeCreateBatchModal}
        onSubmitted={() => {
          closeCreateBatchModal();
        }}
      />
    </>
  );
}
