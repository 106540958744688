import { useWebSocket } from '@app:services';
import { Center, Select, Spinner } from '@chakra-ui/react';
import { Form, Modal } from 'components';
import { useFormik } from 'formik';
import { useStore } from 'store';
import * as Yup from 'yup';

export type AssignToMachineInputSchema = {
  targetLocationId: string;
};
export function AssignProductionToDeviceModal(props: {
  isOpen: boolean;
  onClose: () => void;
  productionRequestRef: { orderId: string; productId: string };
  onSubmitSuccess?: () => void;
  defaultData?: Partial<AssignToMachineInputSchema>;
}) {
  const [store] = useStore();
  const websocket = useWebSocket();

  const form = useFormik<AssignToMachineInputSchema>({
    initialValues: {
      targetLocationId: store.location.productionLines[0].id || '',
      ...props.defaultData,
    },
    validationSchema: Yup.object().shape({
      targetLocationId: Yup.string().required(),
    }),
    onSubmit: ({ targetLocationId }) => {
      websocket.send({
        type: 'user.moveTaskToProductionLine',
        payload: {
          targetLocationId,
          orderId: props.productionRequestRef.orderId,
          productId: props.productionRequestRef.productId,
        },
      });

      if (typeof props.onSubmitSuccess === 'function') props.onSubmitSuccess();
    },
  });

  if (store.location.productionLines.length === 0) {
    return (
      <Center py={5}>
        <Spinner size={'lg'} />
      </Center>
    );
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title='Assign production to device'
      modalContentStyle={{ maxWidth: '90%', width: '400px' }}
    >
      <Form<AssignToMachineInputSchema>
        {...form}
        submitLabel='Assign'
        schema={[
          {
            label: 'Device',
            name: 'targetLocationId',
            render: (value, { handleChange }) => (
              <Select value={value} onChange={(e) => handleChange(e.target.value)}>
                {store.location.productionLines.map((line) => (
                  <option key={line.id} value={line.id}>
                    {line.id}
                  </option>
                ))}
              </Select>
            ),
          },
        ]}
      />
    </Modal>
  );
}
