import { Button, ButtonGroup, Flex, Progress, Text } from '@chakra-ui/react';
import { ListView } from 'components';
import React from 'react';
import { RiFileEditLine } from 'react-icons/ri';
import { useStore } from 'store';
import { MaterialBatch } from '../../models/material';

export function MaterialBatchList(props: { materialId: string }) {
  const [store, dispatch] = useStore();
  const [page, setPage] = React.useState(0);
  const [failedFetch, setFetchFailed] = React.useState(false);

  const material = store.resources.materials.items.find((material) => material.id === props.materialId);
  const batches = material?.batches || [];

  return (
    <>
      <ListView<MaterialBatch>
        title='Material Batch list'
        dataId='id'
        items={batches}
        totalItems={batches.length}
        page={0}
        limit={batches.length}
        isLoading={store.resources.materials.isLoading}
        onChangePage={setPage}
        onRefresh={() => {}}
        onSort={(key, val) => {}}
        emptyMessage={'No batches recorded'}
        errorMessage={failedFetch ? 'Error loading data' : undefined}
        columns={[
          {
            key: '@action',
            title: 'actions',
            render: (data, item) => (
              <ButtonGroup>
                <Button size='sm' variant='outline' colorScheme='blue' onClick={undefined}>
                  <RiFileEditLine />
                </Button>
              </ButtonGroup>
            ),
            props: {
              width: '100px',
            },
          },
          {
            key: 'batchNumber',
            title: 'Batch number',
          },
          {
            key: 'stock',
            title: 'Available stock',
            render: (stock) => (
              <Flex alignItems={'center'} gap={2}>
                <Progress flex={2} value={(stock.available.amount / stock.initial.amount) * 100} />
                <Text flex={1}>
                  {stock.available.amount} / {stock.initial.amount} {stock.initial.unit}
                </Text>
              </Flex>
            ),
          },
          {
            key: 'supplierId',
            title: 'Supplier',
          },
          {
            key: 'expiresAt',
            title: 'Expiration Date',
            render: (val: string) => val.split('T')[0],
          },
        ]}
      />
    </>
  );
}
