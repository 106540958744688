import { PendingOrder, PendingProductInventory } from '@app:types';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { CreateOrderModal, ListView } from 'components';
import { BoxLoader, SimplePageTemplate } from 'components/@common/layout';
import React from 'react';
import { useStore } from 'store';

import { RiArrowUpFill, RiCheckFill, RiDropboxLine, RiInformationFill, RiMore2Fill, RiSettings2Fill } from 'react-icons/ri';

function Content() {
  const [
    {
      orders: { pending },
    },
    dispatch,
  ] = useStore();

  const limit = 10;
  const [page, setPage] = React.useState(0);
  const [failedFetch, setFetchFailed] = React.useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const fetchItems = React.useCallback(
    (forceRefresh: boolean = false) => {
      dispatch('orders', 'fetchPendingOrders', { page, limit, forceRefresh }, undefined, () => setFetchFailed(true));
    },
    [limit, page],
  );

  React.useEffect(() => {
    fetchItems();
  }, [page]);

  return (
    <>
      <Box bgColor='white' padding={3} w='100%' h='100%' borderColor='gray.200' borderWidth={1}>
        <Box w='100%' h='100%' overflowY={'auto'}>
          <ListView<PendingOrder>
            title=''
            dataId='orderId'
            items={pending.items}
            totalItems={pending.totalItems}
            page={pending.page}
            limit={limit}
            isLoading={pending.isLoading}
            onChangePage={setPage}
            onRefresh={fetchItems.bind(null, true)}
            onSort={(key, val) => {
              dispatch('orders', 'sortPendingOrders', { by: key, mode: val, limit }, undefined, () => setFetchFailed(true));
            }}
            emptyMessage={'No pending orders'}
            errorMessage={failedFetch ? 'Error loading data' : undefined}
            headerVisible
            headerActions={[
              {
                label: 'Create order',
                colorScheme: 'blue',
                onClick: onOpen,
              },
            ]}
            columns={[
              {
                key: 'orderNumber',
                title: 'Order Number',
                props: {
                  width: '10%',
                },
              },
              {
                key: 'metadata.orderDate',
                title: 'Order Date',
                render: (data: string) => {
                  const date = new Date(data);
                  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
                  const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
                  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
                  return `${ye}-${mo}-${da}`;
                },
                props: {
                  width: '10%',
                },
                sortedAs: pending.sortedBy['metadata.orderDate'],
              },
              {
                key: 'metadata.name',
                title: 'Creator',
                props: {
                  width: '10%',
                },
              },
              {
                key: 'products',
                title: 'Products to fulfill',
                render: (data, item) => {
                  return <ContentRender orderId={item.orderId} data={data} />;
                },
              },
              {
                key: '@actions',
                title: 'Actions',
                render: (data, item) => <ActionRender orderId={(item as any).id} data={item} />,
                props: {
                  width: '200px',
                },
              },
            ]}
          />
        </Box>
      </Box>
      <CreateOrderModal
        isOpen={isOpen}
        onCancel={onClose}
        onSubmitted={() => {
          onClose();
          fetchItems(true);
        }}
      />
    </>
  );
}

function ContentRender(props: { orderId: string; data: PendingProductInventory }) {
  const { requested, automatic, completed, manual, unprocessed } = props.data;

  return (
    <BoxLoader isLoading={false}>
      <HStack>
        {requested.length > 0 && (
          <Box position={'relative'}>
            <Button
              as={Flex}
              variant='outline'
              colorScheme='gray'
              flexDir='column'
              height='auto'
              pt='10px'
              pb='10px'
              width='190px'
              justifyContent='flex-start'
              cursor='pointer'
            >
              <Flex w='100%'>
                <Text flex='1'>Waiting...</Text>
                <Spinner size='sm' />
              </Flex>
              <Text fontSize='xs' color='gray.400' width='100%' fontWeight='normal'>
                Products are in production
              </Text>
            </Button>
          </Box>
        )}
        {automatic.length > 0 && (
          <Box position={'relative'}>
            <Button
              as={Flex}
              variant='outline'
              colorScheme='blue'
              flexDir='column'
              height='auto'
              pt='10px'
              pb='10px'
              width='190px'
              cursor='pointer'
            >
              <Flex w='100%'>
                <Text flex='1'>{automatic.length} automatic</Text>
                <RiArrowUpFill />
              </Flex>
              <Text fontSize='xs' color='gray.400' width='100%' fontWeight='normal'>
                Click to send to machines
              </Text>
            </Button>
          </Box>
        )}
        {manual.length > 0 && (
          <Box position={'relative'}>
            <Button
              as={Flex}
              variant='outline'
              colorScheme='yellow'
              flexDir='column'
              height='auto'
              pt='10px'
              pb='10px'
              width='190px'
              justifyContent='flex-start'
              cursor='pointer'
            >
              <Flex w='100%'>
                <Text flex='1'>{manual.length} manual</Text>
                <RiSettings2Fill />
              </Flex>
              <Text fontSize='xs' color='gray.400' width='100%' fontWeight='normal'>
                Click to process
              </Text>
            </Button>
          </Box>
        )}
        {unprocessed.length > 0 && (
          <Box position={'relative'}>
            <Button
              as={Flex}
              variant='outline'
              colorScheme='red'
              flexDir='column'
              height='auto'
              pt='10px'
              pb='10px'
              width='190px'
              justifyContent='flex-start'
              cursor='pointer'
            >
              <Flex w='100%'>
                <Text flex='1'>{unprocessed.length} not processed</Text>
                <RiInformationFill />
              </Flex>
              <Text fontSize='xs' color='gray.400' width='100%' fontWeight='normal'>
                Click to add label info
              </Text>
            </Button>
          </Box>
        )}
        {completed.length > 0 && (
          <Box position={'relative'}>
            <Button
              as={Flex}
              variant='outline'
              colorScheme='green'
              flexDir='column'
              height='auto'
              pt='10px'
              pb='10px'
              width='190px'
              justifyContent='flex-start'
              cursor='pointer'
            >
              <Flex w='100%'>
                <Text flex='1'>{completed.length} completed</Text>
                <RiCheckFill />
              </Flex>
              <Text fontSize='xs' color='gray.400' width='100%' fontWeight='normal'>
                Click to mark as fulfilled
              </Text>
            </Button>
          </Box>
        )}
      </HStack>
    </BoxLoader>
  );
}

function ActionRender(props: { orderId: string; data: PendingOrder }) {
  const [store, dispatch] = useStore();

  return (
    <BoxLoader isLoading={false}>
      <ButtonGroup>
        <Menu>
          <MenuButton as={IconButton} icon={<RiMore2Fill />} variant='outline' aria-label='options' />
          <MenuList>
            {/*<MenuItem
              disabled={true}
              onClick={async () => {
                try {
                  setLoading(true);
                  await api.orders.updatePending({
                    body: {},
                    query: {
                      method: 'processor',
                    },
                    params: {
                      orderId: props.orderId,
                    },
                  });
                  props.onUpdate();
                } catch (e) {
                  console.log('error', e);
                  toast({
                    duration: 3000,
                    status: 'error',
                    title: 'Error updating order',
                    description: 'Please try again or contact the administrator.',
                  });
                } finally {
                  setLoading(false);
                }
              }}
            >
              Process order again
            </MenuItem>
            <MenuItem
              isDisabled={props.data.content.every((item) => item.metadata.processedAs !== 'automatic')}
              onClick={async () => {
                try {
                  setLoading(true);
                  await api.orders.updatePending({
                    body: {
                      processManually: true,
                    },
                    query: {
                      method: 'local',
                    },
                    params: {
                      orderId: props.orderId,
                    },
                  });
                  props.onUpdate();
                } catch (e) {
                  console.log('error', e);
                  toast({
                    duration: 3000,
                    status: 'error',
                    title: 'Error updating order',
                    description: 'Please try again or contact the administrator.',
                  });
                } finally {
                  setLoading(false);
                }
              }}
            >
              Process order manually
            </MenuItem>*/}
            <MenuItem
              onClick={async () => {
                dispatch('orders', 'deletePendingOrder', { orderId: props.orderId, refresh: true });
              }}
            >
              Delete order
            </MenuItem>
          </MenuList>
        </Menu>
      </ButtonGroup>
    </BoxLoader>
  );
}

export const PendingOrders = () => {
  return (
    <SimplePageTemplate
      {...{
        title: 'Manage incoming orders',
        icon: RiDropboxLine,
      }}
      content={<Content />}
    />
  );
};
