import { BoxProps, Center, Grid, GridItem, Heading, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';

export type SimplePageTemplateProps = {
  icon?: IconType;
  title: string;
  contentStyle?: BoxProps;
  content: React.ReactElement;
};

const SimplePageTemplate = (
  props: SimplePageTemplateProps = {
    title: 'My Page',
    content: <Text>Hello World</Text>,
  },
) => {
  return (
    <Grid
      h='100vh'
      w='100vw'
      gridTemplateColumns={{
        base: '100%',
      }}
      gridTemplateRows={{
        base: '70px 1fr',
      }}
      gridTemplateAreas={{
        base: `'header' 'content'`,
      }}
    >
      <GridItem gridArea='header' p={5} borderColor='gray.200' borderBottomWidth='1px'>
        <Heading as='h1' size='md' lineHeight='auto' isTruncated={true}>
          {props.icon && (
            <Icon
              mr='4'
              fontSize='16'
              _groupHover={{
                color: 'white',
              }}
              as={props.icon}
            />
          )}
          {props.title}
        </Heading>
      </GridItem>
      <GridItem gridArea='content' p={5} bg='gray.50' h='100%' overflowY={'auto'}>
        <Center {...props.contentStyle} height={'100%'}>
          {props.content}
        </Center>
      </GridItem>
    </Grid>
  );
};

export { SimplePageTemplate };
