import { InnerProductUnits, MaterialBatch, MaterialTypes, OuterProductUnits } from '@ellure/types';
import { Material } from '../../../models/material';
import { BaseApiClass } from '../base-api-class';
import { PaginationResponse } from '../endpoint';

export type CreateMaterialDto = {
  name: string;
  type: MaterialTypes;
};

export type CreateMaterialBatchDto = {
  materialId: string;
  supplierId: string;
  locationId: string;
  type: MaterialTypes;
  batchNumber: string;
  expirationDate: string;
  quantity: {
    amount: number;
    unit: InnerProductUnits | OuterProductUnits;
  };
};

export type FetchMaterialsDto = {
  page?: number;
  limit?: number;
  type?: MaterialTypes[];
};

export class MaterialsApi extends BaseApiClass {
  /** Fetches a list of materials. */
  public readonly fetchList = this.createEndpoint<FetchMaterialsDto, PaginationResponse<Material>>({
    method: 'get',
    endpoint: ['materials'],
    requestConfig: (dto) => ({
      query: {
        page: dto.page?.toString(),
        limit: dto.limit?.toString(),
        type: dto.type,
      },
    }),
  });

  /** Create and returns a new materials. */
  public readonly create = this.createEndpoint<CreateMaterialDto, Material>({
    method: 'post',
    endpoint: ['materials'],
    requestConfig: (dto) => ({
      data: dto,
    }),
  });

  /** Create and returns a new material batch. */
  public readonly createBatch = this.createEndpoint<CreateMaterialBatchDto, MaterialBatch>({
    method: 'post',
    endpoint: ['materials', ':materialId', 'batches'],
    requestConfig: ({ materialId, ...data }) => ({
      params: { materialId },
      data,
    }),
  });
}
