import { Material, MaterialSupplier } from '../../models/material';
import { ProductTemplate } from '../../models/templates';

export type ResourcesState = {
  isCreatingNew: boolean;
  templates: {
    totalItems: number;
    isLoading: boolean;
    page: number;
    limit: number;
    items: ProductTemplate[];
    /*sortedBy: {
      'metadata.orderDate': SortingModes;
    };*/
    lastUpdated: string | null;
  };
  materials: {
    totalItems: number;
    isLoading: boolean;
    page: number;
    limit: number;
    items: Material[];
    /*sortedBy: {
      'metadata.orderDate': SortingModes;
    };*/
    lastUpdated: string | null;
  };
  suppliers: {
    totalItems: number;
    isLoading: boolean;
    page: number;
    limit: number;
    items: MaterialSupplier[];
    /*sortedBy: {
      'metadata.orderDate': SortingModes;
    };*/
    lastUpdated: string | null;
  };
};

export const initialState: ResourcesState = {
  isCreatingNew: false,
  templates: {
    isLoading: false,
    items: [],
    page: -1,
    limit: 10,
    totalItems: 0,
    lastUpdated: null,
  },
  materials: {
    isLoading: false,
    items: [],
    page: -1,
    limit: 10,
    totalItems: 0,
    lastUpdated: null,
  },
  suppliers: {
    isLoading: false,
    items: [],
    page: -1,
    limit: 10,
    totalItems: 0,
    lastUpdated: null,
  },
};
