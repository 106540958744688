import { Slice } from '@app:types';
import { StoreState } from 'store/store';
import actions from './locations.actions';
import reducers, { LocationMutations } from './locations.reducer';
import { initialState, LocationsState } from './locations.state';

const slice: Slice<StoreState, LocationsState, LocationMutations> = {
  name: 'location',
  state: initialState,
  reducers,
  actions,
};

export default slice;
