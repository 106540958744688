import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Button, ButtonGroup, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { Order } from '@ellure/types';
import { ListView } from 'components';
import { BoxLoader, SimplePageTemplate } from 'components/@common/layout';
import React from 'react';
import { useStore } from 'store';

import { RiDownload2Line, RiDropboxLine } from 'react-icons/ri';

function Content() {
  const [
    {
      orders: { archived },
    },
    dispatch,
  ] = useStore();

  const limit = 10;
  const [page, setPage] = React.useState(0);
  const [failedFetch, setFetchFailed] = React.useState(false);

  const fetchItems = React.useCallback(
    (forceRefresh: boolean = false) => {
      dispatch('orders', 'fetchArchivedOrders', { page, limit, forceRefresh }, undefined, () => setFetchFailed(true));
    },
    [limit, page],
  );

  React.useEffect(() => {
    fetchItems();
  }, [page]);

  return (
    <Box bgColor='white' padding={3} w='100%' h='100%' borderColor='gray.200' borderWidth={1}>
      <Box w='100%' h='100%' overflowY={'auto'}>
        <ListView<Order>
          title=''
          items={archived.items}
          totalItems={archived.totalItems}
          page={archived.page}
          limit={limit}
          isLoading={archived.isLoading}
          onChangePage={setPage}
          onRefresh={fetchItems.bind(null, true)}
          onSort={(key, val) => {
            dispatch('orders', 'sortArchivedOrders', { by: key, mode: val, limit }, undefined, () => setFetchFailed(true));
          }}
          emptyMessage={'No pending orders'}
          errorMessage={failedFetch ? 'Error loading data' : undefined}
          dataId='orderId'
          columns={[
            {
              key: 'orderNumber',
              title: 'Order Number',
              props: {
                width: '10%',
              },
            },
            {
              key: 'metadata.orderDate',
              title: 'Order Date',
              render: (data: string) => {
                const date = new Date(data);
                const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
                const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
                const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
                return `${ye}-${mo}-${da}`;
              },
              props: {
                width: '10%',
              },
            },
            {
              key: 'metadata.name',
              title: 'Creator',
              props: {
                width: '10%',
              },
            },
            {
              key: 'content.length',
              title: '# of Products',
            },
            {
              key: '@actions',
              title: 'Actions',
              render: (data, item) => <MenuRender orderId={item.orderId} data={item} />,
              props: {
                width: '200px',
              },
            },
          ]}
        />
      </Box>
    </Box>
  );
}

function MenuRender(props: { orderId: string; data: Order }) {
  const [loading, setLoading] = React.useState(false);

  return (
    <BoxLoader isLoading={false}>
      <ButtonGroup>
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            <RiDownload2Line />
          </MenuButton>
          <MenuList>
            <MenuItem>All</MenuItem>
            <MenuItem>PIF</MenuItem>
            <MenuItem>CPSR</MenuItem>
            <MenuItem>Labels</MenuItem>
          </MenuList>
        </Menu>
      </ButtonGroup>
    </BoxLoader>
  );
}

export const ArchivedOrders = () => {
  return (
    <SimplePageTemplate
      {...{
        title: 'Manage archived orders',
        icon: RiDropboxLine,
      }}
      content={<Content />}
    />
  );
};
