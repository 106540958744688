import React from 'react';

import { Button, ButtonGroup, ButtonProps, Flex, Heading, Spacer, TableRowProps, Text } from '@chakra-ui/react';
import { DataTable, DataTableColumn } from 'components/@common/data-display';
import { BoxLoader } from 'components/@common/layout';

import { SortingModes } from '@app:types';
import { RiArrowLeftSLine, RiArrowRightSLine, RiErrorWarningFill, RiRefreshLine } from 'react-icons/ri';

export type HeaderActionButton = ButtonProps & { label: string };
export type ActionButton = Omit<ButtonProps, 'onClick'> & {
  label: string;
  onClick: (selection: string[]) => Promise<void>;
};

export function ListView<DataType>(props: {
  title: string;
  emptyMessage: string;
  errorMessage?: string;
  actions?: ActionButton[];
  headerActions?: HeaderActionButton[];
  headerVisible?: boolean;

  onRefresh?: () => void;
  onChangePage: (pageToLoad: number) => void;
  onSort: (key: string, newValue: SortingModes) => void;

  items: DataType[];
  isLoading?: boolean;
  page: number;
  limit: number;
  totalItems: number;

  dataId: keyof DataType;
  columns: DataTableColumn<DataType>[];
  rowProps?: (item: DataType) => TableRowProps;
  controlled?: {
    selectable?: boolean;
    selected: string[];
    onSelect: (value: string[]) => void;
  };
}) {
  const [selected, setSelectedItems] = React.useState<string[]>([]);

  const firstItemIndex = Math.min(props.page * props.limit, props.totalItems);
  const totalPages = Math.ceil(props.totalItems / props.limit);

  return (
    <Flex flexDir={'column'} height='100%'>
      {props.headerVisible && (
        <Flex p={3} pb={3} pt={1} alignItems='center'>
          <Heading size='sm'>{props.title}</Heading>
          <Spacer />
          <ButtonGroup gap='0'>
            {props.headerActions &&
              props.headerActions.map(({ label, onClick, ...props }) => (
                <Button {...props} key={label} disabled={props.isLoading} onClick={onClick} size='sm'>
                  {label}
                </Button>
              ))}
            {typeof props.onRefresh === 'function' && (
              <Button size='sm' onClick={props.onRefresh} disabled={props.isLoading}>
                <RiRefreshLine />
              </Button>
            )}
          </ButtonGroup>
        </Flex>
      )}
      <BoxLoader flex={1} isLoading={props.isLoading}>
        <DataTable<DataType>
          emptyMessageIcon={props.errorMessage ? RiErrorWarningFill : undefined}
          emptyMessage={props.errorMessage ? props.errorMessage : props.emptyMessage}
          selected={props.controlled ? props.controlled.selected : selected}
          onSelect={props.controlled ? props.controlled?.onSelect : setSelectedItems}
          data={props.items.length > props.limit ? props.items.slice(0, props.limit) : props.items}
          columns={props.columns}
          onSort={props.onSort}
          dataId={props.dataId}
          rowProps={props.rowProps}
          selectable={props.controlled?.selectable || (props.actions && props.actions.length > 0)}
        />
      </BoxLoader>
      <Flex p={3} pb={0} gap={6} alignItems='center' borderColor='gray.100' borderTopWidth='1px'>
        <ButtonGroup gap='0'>
          {props.actions &&
            props.actions.map(({ label, onClick, ...actionProps }: { label: string; onClick: (selection: string[]) => Promise<void> }) => (
              <Button
                {...actionProps}
                key={label}
                disabled={selected.length === 0 || props.isLoading}
                onClick={undefined /*executeAction.bind(null, onClick)*/}
              >
                {label}
              </Button>
            ))}
        </ButtonGroup>
        <Spacer />
        <Text>
          {firstItemIndex} - {firstItemIndex + props.items.length} of {props.totalItems}
        </Text>
        <ButtonGroup gap='0'>
          <Button
            size='sm'
            disabled={props.page === 0 || props.isLoading}
            onClick={props.onChangePage.bind(null, Math.max(0, props.page - 1))}
          >
            <RiArrowLeftSLine />
          </Button>
          <Button
            size='sm'
            disabled={props.page === totalPages - 1 || props.isLoading}
            onClick={props.onChangePage.bind(null, Math.min(totalPages - 1, props.page + 1))}
          >
            <RiArrowRightSLine />
          </Button>
        </ButtonGroup>
      </Flex>
    </Flex>
  );
}
