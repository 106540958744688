import { ActionContext, SortingModes } from '@app:types';
import api from '@ellure/api-client-admin';
import { SortingKeys } from '@ellure/api-client-admin/build/@api/order-register-endpoints';
import { OrderTemplate } from '@ellure/types';
import { UseToastIntercept } from 'store';
import { StoreState } from 'store/store';
import { dateIsOlderThan } from 'utils';
import { OrderMutations } from './orders.reducer';
import { OrdersState } from './orders.state';

const actions: ActionContext<StoreState, OrderMutations> = {
  createOrder: async function (_context, payload: OrderTemplate) {
    try {
      await api.orders.create({ body: payload });
    } catch (e) {
      throw new UseToastIntercept({
        title: `Error creating order.`,
        description: 'Your action did not succeed.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  },
  fetchPendingOrders: async function ({ mutate, rootState }, payload: { page?: number; limit?: number; forceRefresh?: boolean } = {}) {
    const state = rootState.orders.pending;
    const page = payload.page !== undefined ? payload.page : state.page > -1 ? state.page : 0;
    const limit = payload.limit !== undefined ? payload.limit : state.limit;

    if (page == state.page && state.limit >= limit && !dateIsOlderThan(state.lastUpdated, 3, 'm') && !payload.forceRefresh) return;

    mutate(OrderMutations.START_LOADING, 'pending');

    try {
      const { data } = await api.orders.pending.fetchAll({
        query: {
          page,
          limit,
          showAll: false,
          sort: [
            rootState.orders.pending.sortedBy['metadata.orderDate'] === SortingModes.ASC
              ? SortingKeys.ORDER_DATE
              : SortingKeys.ORDER_DATE_DESC,
          ],
        },
      });

      mutate(OrderMutations.SET_ORDERS, {
        itemSet: 'pending',
        items: data.data,
        page: data.page,
        limit,
        totalItems: data.totalItems,
      });
    } catch (e) {
      throw new Error('Error fetching pending products.');
    } finally {
      mutate(OrderMutations.STOP_LOADING, 'pending');
    }
  },
  sortPendingOrders: async function ({ mutate, rootState }, payload: { by: keyof OrdersState['pending']['sortedBy']; mode: SortingModes }) {
    rootState.orders.pending.sortedBy[payload.by] = payload.mode;
    await this.fetchPendingOrders({ mutate, rootState }, { forceRefresh: true });
    mutate(OrderMutations.SET_SORTING, { itemSet: 'pending', key: payload.by, sortMode: payload.mode });
  },
  deletePendingOrder: async function ({ mutate, rootState }, payload: { orderId: string; refresh: boolean }) {
    mutate(OrderMutations.START_LOADING, 'pending');
    try {
      await api.orders.pending.delete({ params: { orderId: payload.orderId } });
      if (payload.refresh) await this.fetchPendingOrders({ mutate, rootState }, { forceRefresh: true });
    } catch (e) {
      throw new Error('Error deleting order.');
    } finally {
      mutate(OrderMutations.STOP_LOADING, 'pending');
    }
  },
  fetchArchivedOrders: async function ({ mutate, rootState }, payload: { page?: number; limit?: number; forceRefresh?: boolean }) {
    const state = rootState.orders.archived;
    const page = payload.page !== undefined ? payload.page : state.page > -1 ? state.page : 0;
    const limit = payload.limit !== undefined ? payload.limit : state.limit;

    if (page == state.page && state.limit >= limit && !dateIsOlderThan(state.lastUpdated, 3, 'm') && !payload.forceRefresh) return;

    mutate(OrderMutations.START_LOADING, 'archived');

    try {
      const { data } = await api.orders.archived.fetchAll({
        query: {
          page,
          limit,
          /*sort: [
            rootState.orders.archived.sortedBy['metadata.orderDate'] === SortingModes.ASC
              ? SortingKeys.ORDER_DATE
              : SortingKeys.ORDER_DATE_DESC,
          ],*/
        },
      });

      mutate(OrderMutations.SET_ORDERS, {
        itemSet: 'archived',
        items: data.data,
        page: data.page,
        limit,
        totalItems: data.totalItems,
      });
    } catch (e) {
      throw new Error('Error fetching archived orders.');
    } finally {
      mutate(OrderMutations.STOP_LOADING, 'archived');
    }
  },
  sortArchivedOrders: async function (
    { mutate, rootState },
    payload: { by: keyof OrdersState['archived']['sortedBy']; mode: SortingModes; limit: number },
  ) {
    rootState.orders.archived.sortedBy[payload.by] = payload.mode;
    await this.fetchArchivedOrders({ mutate, rootState }, { forceRefresh: true });
    mutate(OrderMutations.SET_SORTING, { itemSet: 'archived', key: payload.by, sortMode: payload.mode });
  },
};

export default actions;
