import { ReducerContext } from '@app:types';
import { ProductionLineDto } from '../../models/production-location';
import { initialState, LocationsState } from './locations.state';

export enum LocationMutations {
  SET_LOCATION = 'set-location',
  SET_PRODUCTION_LINE = 'set-production-line',
}

const reducers: ReducerContext<LocationsState> = {
  [LocationMutations.SET_LOCATION]: function (state: LocationsState, payload: { id?: string; name?: string }) {
    state.id = payload.id || initialState.id;
    state.name = payload.name || initialState.name;
  },
  [LocationMutations.SET_PRODUCTION_LINE]: function (state: LocationsState, payload: ProductionLineDto) {
    const i = state.productionLines.findIndex((line) => line.id === payload.id);
    if (i === -1) state.productionLines.push(payload);
    else state.productionLines[i] = payload;
  },
};

export default reducers;
