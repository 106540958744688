import { useNavigate, useSearchParams } from 'react-router-dom';

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { SimplePageTemplate } from 'components/@common/layout';

import { MaterialList, ProductTemplateList, SupplierList } from 'components';
import { RiStackLine } from 'react-icons/ri';

const limit = 10;
const Content = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  return (
    <>
      <Tabs
        index={parseInt(params.get('view') || '0')}
        variant='enclosed'
        width='100%'
        height='100%'
        display='flex'
        flexDir='column'
        isLazy
        onChange={(index) => {
          params.set('view', index.toString());
          navigate({
            pathname: '',
            search: params.toString(),
          });
        }}
      >
        <TabList>
          <Tab _selected={{ bg: 'white', borderColor: 'gray.200', borderBottom: 0 }}>Templates</Tab>
          <Tab _selected={{ bg: 'white', borderColor: 'gray.200', borderBottom: 0 }}>Materials</Tab>
          <Tab _selected={{ bg: 'white', borderColor: 'gray.200', borderBottom: 0 }}>Suppliers</Tab>
        </TabList>

        <TabPanels bg='white' borderColor='gray.200' borderWidth='1px' flex={1}>
          <TabPanel height='100%'>
            <ProductTemplateList limit={limit} />
          </TabPanel>
          <TabPanel height='100%'>
            <MaterialList limit={limit} />
          </TabPanel>
          <TabPanel height='100%'>
            <SupplierList limit={limit} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export function Manager() {
  return (
    <SimplePageTemplate
      {...{
        title: 'Manage products and materials',
        icon: RiStackLine,
      }}
      content={<Content />}
    />
  );
}
