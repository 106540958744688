import { ProductionLineDto } from 'models/production-location';

export type LocationsState = {
  id: string;
  name: string;
  productionLines: ProductionLineDto[];
};

export const initialState: LocationsState = {
  id: null!,
  name: 'Loading...',
  productionLines: [],
};
