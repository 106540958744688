import { MaterialSupplier } from '../../../models/material';
import { BaseApiClass } from '../base-api-class';
import { PaginationResponse } from '../endpoint';

export type FetchSuppliersDto = {
  page?: number;
  limit?: number;
};

export type CreateSupplierDto = Omit<MaterialSupplier, 'id'>;

export type UpdateSupplierDto = MaterialSupplier;

export class SuppliersApi extends BaseApiClass {
  /** Fetches a list of suppliers. */
  public readonly fetchList = this.createEndpoint<FetchSuppliersDto, PaginationResponse<MaterialSupplier>>({
    method: 'get',
    endpoint: ['suppliers'],
    requestConfig: (dto) => ({
      query: {
        page: dto.page?.toString(),
        limit: dto.limit?.toString(),
      },
    }),
  });

  /** Create and returns a new suppliers. */
  public readonly create = this.createEndpoint<CreateSupplierDto, MaterialSupplier>({
    method: 'post',
    endpoint: ['suppliers'],
    requestConfig: (dto) => ({
      data: dto,
    }),
  });

  /** Updates an existing supplier. */
  public readonly update = this.createEndpoint<UpdateSupplierDto, MaterialSupplier>({
    method: 'patch',
    endpoint: ['suppliers', ':supplierId'],
    requestConfig: ({ id, ...data }) => ({
      params: { id },
      data,
    }),
  });
}
