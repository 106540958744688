import React from 'react';

import { AuthProvider, useAuth, WebSocketProvider } from '@app:services';
import { useNavigate } from 'react-router-dom';

import { MainViews, ResourceViews, SettingsViews } from './views';

import { Frame } from 'components/@common/layout';
import { Route, Routes } from 'react-router-dom';

function App() {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';

  return (
    <AuthProvider>
      <WebSocketProvider
        uri={`${protocol}//${process.env.REACT_APP_API_HOST?.replace('http://', '').replace('https://', '')}/api/dispatch/ws/users`}
        maxRetries={5}
      >
        <AppRouting />
      </WebSocketProvider>
    </AuthProvider>
  );
}

function AppRouting() {
  return (
    <Frame sidebarVisible={true}>
      <Routes>
        <Route path='/' element={<MainViews.PendingOrders />} />
        <Route path='/orders' element={<MainViews.PendingOrders />} />
        <Route path='/devices' element={<MainViews.Production />} />
        <Route path='/archive' element={<MainViews.ArchivedOrders />} />
        <Route path='/resources' element={<ResourceViews.Manager />} />
        <Route path='/resources/:materialId' element={<ResourceViews.MaterialDetail />} />
        <Route path='/settings' element={<SettingsViews.Settings />} />
        <Route path='/signout' element={<Signout />} />
      </Routes>
    </Frame>
  );
}

const Signout = () => {
  const { signout } = useAuth();
  const navigate = useNavigate();
  React.useEffect(() => {
    signout()
      .then(() => {
        navigate('/');
      })
      .catch(() => {
        navigate('/');
      });
  }, []);

  return null;
};

export default App;
