import {
  Flex,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Switch,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { CompoundRatio } from '@ellure/types';
import { CompoundRatioInputField, Form, Modal } from 'components';
import { useFormik } from 'formik';
import React from 'react';
import { useStore } from 'store';
import * as Yup from 'yup';
import { ProductTemplate } from '../../models/templates';

type TemplateInputOptions = {
  forceManual: boolean;
};

export type TemplateInputSchema = {
  name: string;
  specificationsWeight: number;
  specificationsIngredients: string;
  specificationsFeedstock: CompoundRatio[];
  options: TemplateInputOptions;
};

export function TemplateModal(props: { template?: ProductTemplate; isOpen: boolean; onCancel: () => void; onSubmitted: () => void }) {
  const [_store, dispatch] = useStore();

  const toast = useToast();
  const form = useFormik<TemplateInputSchema>({
    initialValues: {
      name: '',
      specificationsWeight: 0,
      specificationsIngredients: '',
      specificationsFeedstock: [],
      options: {
        forceManual: false,
      },
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      specificationsWeight: Yup.number().required(),
      specificationsIngredients: Yup.string().required(),
    }),
    onSubmit: (data, helpers) => {
      const onSuccess = () => {
        props.onSubmitted();
        toast({
          title: 'Update completed.',
          description: 'The template has been updated.',
          status: 'success',
          duration: 2500,
          isClosable: true,
        });
      };

      const onFailed = () => {
        helpers.setSubmitting(false);
      };

      if (props.template) {
        dispatch('resources', 'updateResource', { resource: 'templates', resourceId: props.template.id, data }, onSuccess, onFailed);
      } else {
        dispatch('resources', 'createResource', { resource: 'templates', data }, onSuccess, onFailed);
      }
    },
  });

  React.useEffect(() => {
    if (props.isOpen) {
      form.setSubmitting(false);
    } else {
      form.resetForm();
    }

    if (props.template) {
      form.setValues({
        name: props.template.label,
        specificationsWeight: props.template.specifications.weight,
        specificationsIngredients: props.template.specifications.ingredients,
        specificationsFeedstock: props.template.specifications.feedstock,
        options: {
          forceManual: props.template.specifications.forceManualProcessing,
        },
      });
    }
  }, [props.isOpen, props.template]);

  return (
    <Modal
      title={props.template ? `Update '${props.template.label}'` : 'Create new template'}
      isOpen={props.isOpen}
      onClose={props.onCancel}
      closeOnOverlayClick={false}
      modalContentStyle={{ maxW: '800px', w: '90%' }}
    >
      <Form<TemplateInputSchema>
        {...form}
        submitLabel={props.template ? 'Update' : 'Create'}
        schema={[
          {
            label: 'Product name',
            name: 'name',
          },
          {
            label: 'Product size',
            name: 'specificationsWeight',
            render: (value, { handleChange }) => {
              const format = (val: number) => val.toFixed(2) + ' gram';
              const parse = (val: string) => parseFloat(val.replace(/ gram/, ''));

              return (
                <NumberInput
                  onChange={(valueString) => handleChange(parse(valueString))}
                  value={format(value as number)}
                  min={0}
                  step={0.1}
                  precision={2}
                  max={50}
                  variant='filled'
                >
                  <NumberInputField type='text' />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              );
            },
          },
          {
            label: 'Product ingredient list',
            name: 'specificationsIngredients',
            render: (val, { handleChange }) => (
              <Textarea onChange={(e) => handleChange(e.target.value)} value={val as string} resize={'none'} variant='filled' />
            ),
          },
          {
            label: 'Product material components',
            name: 'specificationsFeedstock',
            render: (value, { handleChange }) => {
              return <CompoundRatioInputField value={value as CompoundRatio[]} onChange={handleChange} />;
            },
          },
          {
            label: 'Options',
            name: 'options',
            render: (value, { handleChange }) => {
              const onChange = (e: any) => {
                handleChange({
                  forceManual: e.target.checked,
                });
              };

              return (
                <Stack>
                  <Flex bgColor='gray.100' borderRadius='md' p={4} gap={4} alignItems='center'>
                    <Switch id='forceManual' isChecked={(value as TemplateInputOptions).forceManual} onChange={onChange} />
                    <FormLabel htmlFor='forceManual' m={0}>
                      Force products to be processed manually.
                    </FormLabel>
                  </Flex>
                </Stack>
              );
            },
          },
        ]}
      />
    </Modal>
  );
}
