import { Slice } from '@app:types';
import { StoreState } from 'store/store';
import actions from './orders.actions';
import reducers, { OrderMutations } from './orders.reducer';
import { OrdersState, initialState } from './orders.state';

const slice: Slice<StoreState, OrdersState, OrderMutations> = {
  name: 'orders',
  state: initialState,
  reducers,
  actions,
};

export default slice;
