import { useToast } from '@chakra-ui/react';
import { MaterialTypes } from '@ellure/types';
import { Form, Modal } from 'components';
import { useFormik } from 'formik';
import { Material } from 'models/material';
import React from 'react';
import { useStore } from 'store';
import * as Yup from 'yup';

export type MaterialInputSchema = {
  name: string;
  type: MaterialTypes;
};

export function MaterialModal(props: { material?: Material; isOpen: boolean; onCancel: () => void; onSubmitted: () => void }) {
  const [_store, dispatch] = useStore();

  const toast = useToast();
  const form = useFormik<MaterialInputSchema>({
    initialValues: {
      name: '',
      type: MaterialTypes.feedstock,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required().min(3),
    }),
    onSubmit: (data, helpers) => {
      const onSuccess = () => {
        props.onSubmitted();
        toast({
          title: 'Update completed.',
          description: 'The feedstock has been updated.',
          status: 'success',
          duration: 2500,
          isClosable: true,
        });
      };

      const onFailed = () => {
        helpers.setSubmitting(false);
      };

      if (props.material) {
        dispatch('resources', 'updateResource', { resource: 'materials', resourceId: props.material.id, data }, onSuccess, onFailed);
      } else {
        dispatch('resources', 'createResource', { resource: 'materials', data }, onSuccess, onFailed);
      }
    },
  });

  React.useEffect(() => {
    if (props.isOpen) {
      form.setSubmitting(false);
    } else {
      form.resetForm();
    }

    if (props.material) {
      form.setValues({
        name: props.material.label,
        type: props.material.type,
      });
    }
  }, [props.isOpen, props.material]);

  return (
    <Modal
      title={props.material ? `Update '${props.material.label}'` : 'Create new material'}
      isOpen={props.isOpen}
      onClose={props.onCancel}
      modalContentStyle={{ maxW: '800px', w: '90%' }}
    >
      <Form<MaterialInputSchema>
        {...form}
        submitLabel={props.material ? 'Update' : 'Create'}
        schema={[
          {
            label: 'Material name',
            name: 'name',
          },
        ]}
      />
    </Modal>
  );
}
