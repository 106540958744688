import axios from 'axios';
import { UserApi } from './authentication-service/user.controller';
import { OrderCollectionApi } from './order-registration-service/orderCollection.controller';
import { MaterialsApi } from './product-resource-service/materials.controller';
import { SuppliersApi } from './product-resource-service/suppliers.controller';
import { TemplatesApi } from './product-resource-service/templates.controller';

export function buildApi(host: string) {
  const services = {
    authentication: `${host}/api/auth`,
    orderRegistration: `${host}/api/register`,
    productResources: `${host}/api/resources`,
  } as const;

  const httpAgent = axios.create({
    baseURL: host,
    withCredentials: true,
    responseType: 'json',
  });

  httpAgent.defaults.headers.common['Content-Type'] = 'application/json';

  return {
    api: {
      user: new UserApi(httpAgent, { URLprefix: services.authentication }),
      collector: new OrderCollectionApi(httpAgent, { URLprefix: services.orderRegistration }),
      resources: {
        templates: new TemplatesApi(httpAgent, { URLprefix: services.productResources }),
        materials: new MaterialsApi(httpAgent, { URLprefix: services.productResources }),
        suppliers: new SuppliersApi(httpAgent, { URLprefix: services.productResources }),
      },
    },
    httpAgent,
  };
}

const { api, httpAgent } = buildApi((process.env.REACT_APP_API_HOST as string) || '');
export { api, httpAgent };
