import { Store } from '@app:types';
import authentication from './authentication/authentication.slice';
import { AuthenticationState } from './authentication/authentication.state';
import location from './locations/locations.slice';
import { LocationsState } from './locations/locations.state';
import orders from './orders/orders.slice';
import { OrdersState } from './orders/orders.state';
import resources from './resources/resources.slice';
import { ResourcesState } from './resources/resources.state';

export type StoreState = {
  orders: OrdersState;
  location: LocationsState;
  authentication: AuthenticationState;
  resources: ResourcesState;
};

export default new Store<StoreState>({
  orders,
  location,
  authentication,
  resources,
});
