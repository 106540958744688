import { useToast } from '@chakra-ui/react';
import { Form, Modal, ProductInputSchema, ProductsInput } from 'components';
import { useFormik } from 'formik';
import React from 'react';
import { useStore } from 'store';
import * as Yup from 'yup';

export type OrderInputSchema = {
  name: string;
  email: string;
  orderDate: string;
  content: Record<string, string | number>[];
};
export function CreateOrderModal(props: {
  isOpen: boolean;
  onCancel: () => void;
  onSubmitted: () => void;
  defaultData?: Partial<OrderInputSchema>;
}) {
  const [_store, dispatch] = useStore();

  const toast = useToast();
  const form = useFormik<OrderInputSchema>({
    initialValues: {
      name: '',
      email: '',
      orderDate: new Date().toISOString().split('T')[0],
      content: props.defaultData?.content || [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string().required(),
      orderDate: Yup.string().required(),
      content: Yup.array().min(1, 'Order must have at least 1 product.'),
    }),
    onSubmit: ({ content, ...values }, helpers) => {
      dispatch(
        'orders',
        'createOrder',
        {
          metadata: values,
          content: content.map((product) => ({
            derivedFrom: product,
            type: 'lipstick',
          })),
        },
        () => {
          props.onSubmitted();
          toast({
            title: 'Order created!',
            description: 'The order has been submitted.',
            status: 'success',
            duration: 2500,
            isClosable: true,
          });
        },
        () => {
          helpers.setSubmitting(false);
        },
      );
    },
  });

  React.useEffect(() => {
    if (props.isOpen) {
      form.setSubmitting(false);
    } else {
      form.resetForm();
    }
  }, [props.isOpen]);


  return (
    <Modal
      isOpen={props.isOpen}
      closeOnOverlayClick={false}
      onClose={props.onCancel}
      title='Create new order'
      modalContentStyle={{ maxWidth: '90%', width: '800px' }}
    >
      <Form<OrderInputSchema>
        {...form}
        submitLabel='Create'
        schema={[
          {
            label: 'Customer name',
            name: 'name',
          },
          {
            label: 'Customer email',
            name: 'email',
          },
          {
            label: 'Order date',
            name: 'orderDate',
            props: {
              type: 'date',
            },
          },
          {
            label: 'Products',
            name: 'content',
            render: (value, { handleChange }) => <ProductsInput value={value as ProductInputSchema[]} onChange={handleChange} />,
          },
        ]}
      />
    </Modal>
  );
}
