import { Button, FormControl, FormLabel, Input, InputProps, Stack } from '@chakra-ui/react';
import { BoxLoader } from 'components/@common/layout';
import { FormikErrors, FormikTouched } from 'formik';

interface IInput<Schema, K extends keyof Schema> {
  label: string;
  name: K;
  props?: Omit<InputProps, 'onChange'>;
  render?: (
    value: Schema[K],
    handlers: { handleChange: (value: any) => void; handleTouched: () => void },
    input: IInput<Schema, keyof Schema>,
  ) => React.ReactNode;
}

export function Form<Schema>(form: {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any) => void;
  setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean) => Promise<void> | Promise<FormikErrors<Schema>>;
  isSubmitting: boolean;
  values: Schema;
  errors: FormikErrors<Schema>;
  touched: FormikTouched<Schema>;
  schema: IInput<Schema, keyof Schema>[];
  submitLabel: string;
}) {
  return (
    <form onSubmit={form.handleSubmit} noValidate>
      <BoxLoader isLoading={form.isSubmitting}>
        <Stack gap={3} p={4}>
          {form.schema.map((input) => {
            const value = form.values[input.name];
            return (
              <FormControl isInvalid={form.touched[input.name] && !!form.errors[input.name]} key={input.name.toString()}>
                <FormLabel>{input.label}</FormLabel>
                {input.render ? (
                  input.render(
                    form.values[input.name],
                    {
                      handleChange: form.setFieldValue.bind(null, input.name as string),
                      handleTouched: form.setFieldTouched.bind(null, input.name as string),
                    },
                    input,
                  )
                ) : (
                  <Input
                    placeholder=''
                    variant='filled'
                    {...input.props}
                    id={input.name as string}
                    name={input.name as string}
                    onChange={form.handleChange}
                    onBlur={() => form.setFieldTouched(input.name as string)}
                    value={value as any as string}
                  />
                )}
              </FormControl>
            );
          })}
          <Button type='submit' colorScheme='blue' disabled={form.isSubmitting}>
            {form.submitLabel}
          </Button>
        </Stack>
      </BoxLoader>
    </form>
  );
}
