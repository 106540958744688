import * as utils from 'utils';

import {
  Box,
  ButtonProps,
  ChakraProps,
  Checkbox,
  CheckboxGroup,
  Flex,
  Icon,
  Table,
  TableCellProps,
  TableContainer,
  TableRowProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import { SortingModes } from '@app:types';
import { IconType } from 'react-icons/lib';
import { RiArrowDownLine, RiArrowUpLine, RiMailCloseLine } from 'react-icons/ri';

export type DataTableColumn<Datatype> = {
  key: string;
  title: string;
  sortedAs?: SortingModes;
  render?: (data: any, item: Datatype) => React.ReactNode;
  actions?: (ButtonProps & { label: string })[];
  props?: TableCellProps;
};

const DataTable = <Datatype extends unknown>(props: {
  emptyMessage: string;
  emptyMessageIcon?: IconType;
  data: Datatype[];
  dataId: keyof Datatype;
  selected: string[];
  onSelect: (selection: string[]) => void;
  onSort: (key: string, newValue: SortingModes) => void;
  columns: DataTableColumn<Datatype>[];
  rowProps?: (item: Datatype) => TableRowProps;
  selectable?: boolean;
}) => {
  return (
    <>
      <TableContainer overflowX='hidden' height='100%'>
        <Table bg='white' height={props.data.length === 0 ? '100%' : undefined}>
          <Thead>
            <Tr>
              {props.selectable && (
                <Th p={1} pl={3}>
                  <Checkbox
                    disabled={props.data.length === 0}
                    isChecked={props.selected.length === props.data.length}
                    isIndeterminate={props.selected.length > 0 && props.selected.length < props.data.length}
                    onChange={(e) => {
                      if (!e.target.checked) props.onSelect([]);
                      else props.onSelect(props.data.map((item: Datatype) => item[props.dataId] as any as string));
                    }}
                  />
                </Th>
              )}
              {props.columns &&
                props.columns.map(({ key, title, sortedAs }, i) => {
                  let _props: ChakraProps = {};
                  const _sort = sortedAs || SortingModes.NONE;
                  if (i === 0 && !props.selectable) _props = { p: 1, pl: 3 };
                  return (
                    <Th
                      {..._props}
                      key={key.toString()}
                      onClick={
                        _sort === SortingModes.NONE
                          ? undefined
                          : props.onSort.bind(null, key, _sort === SortingModes.ASC ? SortingModes.DESC : SortingModes.ASC)
                      }
                      cursor={_sort === SortingModes.NONE ? undefined : 'pointer'}
                      transition='ease 0.3s'
                      _hover={
                        _sort === SortingModes.NONE
                          ? undefined
                          : {
                              backgroundColor: 'var(--chakra-colors-blue-100);',
                            }
                      }
                    >
                      <Flex>
                        <Box as='span' flex={1}>
                          {title.toString()}
                        </Box>
                        {_sort === SortingModes.ASC && <RiArrowDownLine display='inline-block' />}
                        {_sort === SortingModes.DESC && <RiArrowUpLine display='inline-block' />}
                      </Flex>
                    </Th>
                  );
                })}
            </Tr>
          </Thead>
          {props.data.length === 0 && (
            <Tbody alignItems='center' opacity={0.3}>
              <Tr>
                <Td colSpan={props.columns.length}>
                  <Text flex={1} textAlign={'center'}>
                    <Icon h={'80px'} w={'80px'} as={props.emptyMessageIcon || RiMailCloseLine} /> <br />
                    {props.emptyMessage}
                  </Text>
                </Td>
              </Tr>
            </Tbody>
          )}
          <Tbody>
            <CheckboxGroup value={props.selected} onChange={props.onSelect}>
              {props.data.map((item: Datatype) => (
                <Tr {...(props.rowProps ? props.rowProps(item) : {})} key={item[props.dataId] as any as string}>
                  {props.selectable && (
                    <Td p={1} pl={3} w='30px'>
                      <Checkbox value={item[props.dataId] as any as string} />
                    </Td>
                  )}
                  {props.columns &&
                    props.columns.map(({ key, render, ...column }, i) => {
                      let _props = column.props || {};
                      if (i === 0 && !props.selectable) _props = Object.assign({}, column.props, { p: 1, pl: 3 });

                      let node = (key === '@actions' ? '' : utils.getValueByKeyPath(item, key)) as React.ReactNode;
                      if (typeof render === 'function') node = render(node, item);
                      return (
                        <Td {..._props} key={key.toString()}>
                          {node}
                        </Td>
                      );
                    })}
                </Tr>
              ))}
            </CheckboxGroup>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export { DataTable };
