import {
  Button,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { CompoundRatio, InnerProductUnits } from '@ellure/types';
import { BoxLoader } from 'components';
import React from 'react';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { useStore } from 'store';
import { Material } from '../../../models/material';

export function CompoundRatioInputField(props: { value: CompoundRatio[]; onChange: (value: any) => void }) {
  const [store, dispatch] = useStore();

  React.useEffect(() => {
    dispatch('resources', 'fetchResources', { resource: 'materials', page: 0, limit: 30 });
  }, []);

  const handleChange = (index: number, value: CompoundRatio) => {
    const newValue = [...props.value];
    newValue[index] = value;
    props.onChange(newValue);
  };

  const handleRemove = (index: number) => {
    const newValue = [...props.value];
    newValue.splice(index, 1);
    props.onChange(newValue);
  };

  return (
    <BoxLoader isLoading={store.resources.materials.isLoading}>
      <Stack>
        <Table>
          <Thead>
            <Tr>
              <Th w='calc(100% - 390px)' p={1} pl={3}>
                Materials
              </Th>
              <Th w='150px' p={1} pl={3}>
                Known quantity?
              </Th>
              <Th w='120px' p={1} pl={3}>
                Quantity
              </Th>
              <Th w='120px' p={1} pl={3}>
                Unit
              </Th>
              <Th w='50px' p={1} pl={3} />
            </Tr>
          </Thead>
          <Tbody>
            {props.value.map((val, index) => (
              <MaterialRatioInputRow
                key={index}
                value={val}
                materialOptions={store.resources.materials.items}
                onChange={handleChange.bind(null, index)}
                onRemove={handleRemove.bind(null, index)}
              />
            ))}
          </Tbody>
        </Table>
        <Button
          disabled={store.resources.materials.items.length === 0}
          onClick={() => {
            const newValue = [...props.value];
            newValue.push({
              materialId: store.resources.materials.items[0].id,
              amount: 'unknown',
            });
            props.onChange(newValue);
          }}
        >
          + Add component
        </Button>
      </Stack>
    </BoxLoader>
  );
}

function MaterialRatioInputRow(props: {
  value: CompoundRatio;
  materialOptions: Material[];
  onChange: (value: CompoundRatio) => void;
  onRemove: () => void;
}) {
  const isUnknown = props.value.amount === 'unknown';

  const handleChange = (key: string, event: any) => {
    const newValue = JSON.parse(JSON.stringify(props.value));
    if (key === 'switch') {
      if (event.target.checked) {
        newValue.amount = 'unknown';
        newValue.unit = undefined;
      } else {
        newValue.amount = 0;
        newValue.unit = InnerProductUnits.GRAM;
      }
    } else if (key === 'amount') newValue[key] = event;
    else newValue[key] = event.target.value;

    props.onChange(newValue);
  };

  return (
    <Tr>
      <Td p={1} pl={3}>
        <Select onChange={handleChange.bind(null, 'materialId')} flex={1} value={props.value.materialId} variant='filled'>
          {props.materialOptions.map((item) => (
            <option key={item.id} value={item.id}>
              {item.label}
            </option>
          ))}
        </Select>
      </Td>
      <Td p={1} pl={3}>
        <Switch onChange={handleChange.bind(null, 'switch')} isChecked={isUnknown} />
      </Td>
      <Td p={1} pl={3}>
        <NumberInput
          isDisabled={isUnknown}
          onChange={handleChange.bind(null, 'amount')}
          value={!isUnknown ? props.value.amount : 0}
          min={0}
          step={0.1}
          precision={2}
          max={props.value.unit === InnerProductUnits.PERCENT ? 100 : 50}
          variant='filled'
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Td>
      <Td p={1} pl={3}>
        <Select onChange={handleChange.bind(null, 'unit')} flex={1} variant='filled' value={props.value.unit} disabled={isUnknown}>
          <option value={InnerProductUnits.GRAM}>gram</option>
          <option value={InnerProductUnits.PERCENT}>%</option>
        </Select>
      </Td>
      <Td p={1} pl={3}>
        <Button variant='ghost' p={0} size='sm' onClick={props.onRemove}>
          <RiDeleteBin2Line />
        </Button>
      </Td>
    </Tr>
  );
}
