import { Button, ButtonGroup, useDisclosure } from '@chakra-ui/react';
import { ListView, Modal } from 'components/@common';
import React from 'react';
import { useStore } from 'store';
import { ProductTemplate } from '../../models/templates';
import { TemplateModal } from './template-modal';

export function ProductTemplateList(props: { limit: number }) {
  const [store, dispatch] = useStore();
  const [page, setPage] = React.useState(0);
  const [failedFetch, setFetchFailed] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const listState = store.resources.templates;

  const fetchItems = React.useCallback(
    (forceRefresh: boolean = false) => {
      dispatch('resources', 'fetchResources', { resource: 'templates', page, limit: props.limit, forceRefresh }, undefined, () =>
        setFetchFailed(true),
      );
    },
    [props.limit, page],
  );

  React.useEffect(() => {
    fetchItems();
  }, [page]);

  return (
    <>
      <ListView<ProductTemplate>
        title='Product templates'
        dataId='id'
        items={listState.items}
        totalItems={listState.totalItems}
        page={listState.page}
        limit={listState.limit}
        isLoading={listState.isLoading}
        headerVisible
        headerActions={[
          {
            label: 'Create template',
            colorScheme: 'blue',
            onClick: onOpen,
          },
        ]}
        onChangePage={setPage}
        onRefresh={fetchItems.bind(null, true)}
        onSort={(key, val) => {}}
        emptyMessage={'No templates defined'}
        errorMessage={failedFetch ? 'Error loading data' : undefined}
        rowProps={(item) => ({
          opacity: (item.specifications as any).forceGhostProcessing ? 0.5 : 1,
        })}
        columns={[
          {
            key: '@action',
            title: 'actions',
            render: (data, item) => <ActionButtons {...item} onChange={fetchItems.bind(null, true)} />,
            props: {
              w: '200px',
            },
          },
          {
            key: 'specifications',
            title: 'Type',
            render: (val) => {
              if (val.forceGhostProcessing) return 'Ghost';
              if (val.forceManualProcessing) return 'Manual';
              return 'Automatic';
            },
          },
          {
            key: 'productTemplateDisplayName',
            title: 'Template name',
          },
          {
            key: 'specifications.weight',
            title: 'Specified size',
            render: (val) => parseFloat(val).toFixed(2) + ' gram',
          },
          {
            key: 'specifications.feedstock.length',
            title: 'Specified components',
          },
        ]}
      />
      <TemplateModal
        isOpen={isOpen}
        onCancel={onClose}
        onSubmitted={() => {
          onClose();
          fetchItems(true);
        }}
      />
    </>
  );
}

function ActionButtons(
  props: ProductTemplate & {
    onChange: () => void;
  },
) {
  const [store, dispatch] = useStore();
  const { isOpen: isEditModalOpen, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useDisclosure();
  const { isOpen: isFileModalOpen, onOpen: onOpenFileModal, onClose: onCloseFileModal } = useDisclosure();

  return (
    <>
      <ButtonGroup>
        <Button
          size='sm'
          variant='outline'
          colorScheme='blue'
          onClick={onOpenEditModal}
          isDisabled={(props.specifications as any).forceGhostProcessing}
        >
          Edit
        </Button>
        {/*<Button
          size='sm'
          variant='outline'
          colorScheme='blue'
          onClick={onOpenFileModal}
          isDisabled={(props.specifications as any).forceGhostProcessing}
        >
          <RiFileEditLine />
        </Button>*/}
      </ButtonGroup>
      <TemplateModal
        template={props}
        isOpen={isEditModalOpen}
        onCancel={onCloseEditModal}
        onSubmitted={() => {
          onCloseEditModal();
          props.onChange();
        }}
      />
      <Modal isOpen={isFileModalOpen} onClose={onCloseFileModal} title='Manage attachments' modalContentStyle={{ maxW: '800px', w: '90%' }}>
        <p>hello</p>
      </Modal>
    </>
  );
}
