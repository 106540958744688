import { useWebSocket } from '@app:services';
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Spacer,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { Device, DeviceActionRequest, DeviceStatus } from '@ellure/types';
import { ProductionLineDto } from 'models/production-location';
import { RiBilibiliLine, RiMore2Fill, RiRunLine, RiSignalWifiOffLine, RiZzzLine } from 'react-icons/ri';
import { ProductionRequestCard } from './production-request-card';

function ManualCard() {
  return (
    <Flex width='300px' flexDir='column' bgColor='white' borderColor='gray.200' borderWidth={1}>
      <Flex alignItems='center' gap='10px' p='5px' pl={3} borderBottom='2px' borderColor='gray.200'>
        <Icon borderRadius='full' as={RiRunLine} h='20px' w='20px' />
        <Heading size='md'>Manual</Heading>
        <Spacer />
      </Flex>
      <Flex alignItems='center' gap={6} height='100%' w='100%' minHeight='100px'>
        <ButtonGroup flex={1} gap={1} h='100%' w='100%'>
          <Button borderRadius={0} h='100%' w='100%' disabled></Button>
        </ButtonGroup>
      </Flex>
    </Flex>
  );
}

function DeviceCard(props: Device) {
  const { send } = useWebSocket();

  return (
    <Flex width='300px' flexDir='column' bgColor='white' borderColor='gray.200' borderWidth={1}>
      <Flex alignItems='center' gap='10px' p='5px' pl={3} borderBottom='2px' borderColor='gray.200'>
        <Icon borderRadius='full' as={RiBilibiliLine} h='20px' w='20px' />
        <Heading size='md'>{props.settings.name}</Heading>
        <Spacer />
        <Badge w={'70px'} p={2} textAlign={'center'}>
          {props.status}
        </Badge>
        <ButtonGroup>
          <Menu>
            <MenuButton
              size={'sm'}
              as={IconButton}
              icon={<RiMore2Fill />}
              variant='outline'
              aria-label='options'
              isDisabled={props.status === DeviceStatus.OFFLINE}
            />
            <MenuList>
              <MenuItem>Shutdown</MenuItem>
            </MenuList>
          </Menu>
        </ButtonGroup>
      </Flex>
      {(props as any).actionProgress && (
        <Flex alignItems='center' borderColor='gray.200' borderWidth='1px'>
          <Progress border={0} size='lg' hasStripe flex={1} value={(props as any).actionProgress.value} bg='white' />
        </Flex>
      )}
      <Flex alignItems='center' gap={1} height='100%' w='100%' minHeight='100px' flexDir={'column'}>
        <DeviceActionButton
          actionRequest={props.actionRequest}
          status={props.status}
          onRespond={(actionId: string) => {
            if (!props.actionRequest) return;
            send({
              type: 'user.deviceResponse',
              payload: {
                deviceId: props.id,
                requestId: props.actionRequest.requestId,
                actionId,
              },
            });
          }}
        />
      </Flex>
    </Flex>
  );
}

function DeviceActionButton(props: {
  actionRequest: DeviceActionRequest | null;
  status: DeviceStatus;
  onRespond: (actionId: string) => void;
}) {
  if (props.status === DeviceStatus.OFFLINE) {
    return (
      <ButtonGroup flex={1} gap={1} h='100%' w='100%'>
        <Flex alignItems='stretch' w='100%'>
          <Button borderRadius={0} h='100%' w='100%' disabled>
            <RiSignalWifiOffLine />
          </Button>
        </Flex>
      </ButtonGroup>
    );
  }

  if (!props.actionRequest) {
    return (
      <ButtonGroup flex={1} gap={1} h='100%' w='100%'>
        <Button borderRadius={0} h='100%' w='100%' disabled>
          <Spinner size='xs' />
        </Button>
      </ButtonGroup>
    );
  }

  return (
    <>
      <Flex flex={1} p={3} flexDir='column'>
        <Text fontSize={'xs'}>
          <i>Action required</i>
        </Text>
        <Text>
          <b>{props.actionRequest.message}</b>
        </Text>
      </Flex>
      <ButtonGroup gap='2px' bg={'gray.400'} width='100%' height={'100%'}>
        {props.actionRequest.actions.map((action) => (
          <Button
            key={action.label}
            style={{ margin: 0 }}
            borderRadius={0}
            h='100%'
            w='100%'
            onClick={props.onRespond.bind(null, action.actionId)}
          >
            {action.label}
          </Button>
        ))}
      </ButtonGroup>
    </>
  );
}

export function ProductionLine(props: ProductionLineDto) {
  return (
    <Flex w='100%' height={'200px'} gap={3}>
      {props.device ? <DeviceCard {...props.device} /> : <ManualCard />}
      <Box overflowX={'auto'} overflowY={'hidden'} flex={1} p={3} bgColor='gray.200' borderColor='gray.200' borderWidth={1}>
        {props.queue.length === 0 ? (
          <Center height={'100%'} opacity={0.3}>
            <Text flex={1} textAlign={'center'}>
              <Icon h={'80px'} w={'80px'} as={RiZzzLine} /> <br />
              Waiting for new tasks...
            </Text>
          </Center>
        ) : (
          <HStack height={'100%'}>
            {props.queue.map((request, i) => (
              <ProductionRequestCard
                {...request}
                key={request.productId}
                isInProduction={i === 0 && props.firstQueueItemIsStaged}
                isNext={(i === 0 && !props.firstQueueItemIsStaged) || (i === 1 && props.firstQueueItemIsStaged)}
              />
            ))}
          </HStack>
        )}
      </Box>
    </Flex>
  );
}
