import { CompoundRatio, ProductTemplate } from '@ellure/types';
import { BaseApiClass } from '../base-api-class';
import { PaginationResponse } from '../endpoint';

export type FetchTemplatesDto = {
  page?: number;
  limit?: number;
  ghost?: boolean;
  name?: string;
};

export type CreateTemplateDto = {
  name: string;
  specifications: {
    weight: number;
    ingredients: string;
    forceManual?: boolean;
    feedstock: CompoundRatio[];
  };
};

export type UpdateTemplateDto = Omit<CreateTemplateDto, 'type'> & { templateId: string };

export class TemplatesApi extends BaseApiClass {
  /** Fetches a list of templates. */
  public readonly fetchList = this.createEndpoint<FetchTemplatesDto, PaginationResponse<ProductTemplate>>({
    method: 'get',
    endpoint: ['templates'],
    requestConfig: (dto) => ({
      query: {
        page: dto.page?.toString(),
        limit: dto.limit?.toString(),
      },
    }),
  });

  /** Create and returns a new templates. */
  public readonly create = this.createEndpoint<CreateTemplateDto, ProductTemplate>({
    method: 'post',
    endpoint: ['templates'],
    requestConfig: (dto) => ({
      data: dto,
    }),
  });

  /** Updates an existing template. */
  public readonly update = this.createEndpoint<UpdateTemplateDto, ProductTemplate>({
    method: 'patch',
    endpoint: ['templates', ':templateId'],
    requestConfig: ({ templateId, ...data }) => ({
      params: { templateId },
      data,
    }),
  });
}
