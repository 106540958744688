import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

function Modal(props: {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  modalContentStyle?: ModalContentProps;
  closeOnOverlayClick?: boolean;
  children: React.ReactElement;
}) {
  return (
    <ChakraModal isOpen={props.isOpen} onClose={props.onClose} isCentered={true} closeOnOverlayClick={props.closeOnOverlayClick}>
      <ModalOverlay />
      <ModalContent {...Object.assign({ maxHeight: '90%', overflowY: 'auto' }, props.modalContentStyle)}>
        <ModalHeader>{props.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={2}>{props.children}</ModalBody>
      </ModalContent>
    </ChakraModal>
  );
}

export { Modal };
