import { useToast } from '@chakra-ui/react';
import { Form, Modal } from 'components';
import { useFormik } from 'formik';
import React from 'react';
import { useStore } from 'store';
import * as Yup from 'yup';
import { MaterialSupplier } from '../../models/material';

export type SupplierInputSchema = {
  name: string;
  email: string;
  address: string;
  postcode: string;
  city: string;
  country: string;
};

export function SupplierModal(props: { supplier?: MaterialSupplier; isOpen: boolean; onCancel: () => void; onSubmitted: () => void }) {
  const [_store, dispatch] = useStore();

  const toast = useToast();
  const form = useFormik<SupplierInputSchema>({
    initialValues: {
      name: '',
      email: '',
      address: '',
      postcode: '',
      city: '',
      country: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().min(5).required(),
      email: Yup.string().email().required(),
      address: Yup.string().min(1).required(),
      postcode: Yup.string().min(1).required(),
      city: Yup.string().min(1).required(),
      country: Yup.string().min(2).max(2).required(),
    }),
    onSubmit: (data, helpers) => {
      const onSuccess = () => {
        props.onSubmitted();
        toast({
          title: 'Update completed.',
          description: 'The supplier has been updated.',
          status: 'success',
          duration: 2500,
          isClosable: true,
        });
      };

      const onFailed = () => {
        helpers.setSubmitting(false);
      };

      if (props.supplier) {
        dispatch('resources', 'updateResource', { resource: 'suppliers', resourceId: props.supplier.id, data }, onSuccess, onFailed);
      } else {
        dispatch('resources', 'createResource', { resource: 'suppliers', data }, onSuccess, onFailed);
      }
    },
  });

  React.useEffect(() => {
    if (props.isOpen) {
      form.setSubmitting(false);
    } else {
      form.resetForm();
    }

    if (props.supplier) {
      form.setValues({
        name: props.supplier.name,
        email: props.supplier.email,
        address: props.supplier.address,
        city: props.supplier.city,
        country: props.supplier.countryCode,
        postcode: props.supplier.postcode,
      });
    }
  }, [props.isOpen, props.supplier]);

  return (
    <Modal
      title={props.supplier ? `Update '${props.supplier.name}'` : 'Create new supplier'}
      isOpen={props.isOpen}
      onClose={props.onCancel}
      closeOnOverlayClick={false}
      modalContentStyle={{ maxW: '800px', w: '90%' }}
    >
      <Form<SupplierInputSchema>
        {...form}
        submitLabel={props.supplier ? 'Update' : 'Create'}
        schema={[
          {
            label: 'Supplier name',
            name: 'name',
          },
          {
            label: 'Supplier contact email',
            name: 'email',
          },
          {
            label: "Supplier's address",
            name: 'address',
          },
          {
            label: 'Supplier postcode',
            name: 'postcode',
          },
          {
            label: 'Supplier city',
            name: 'city',
          },
          {
            label: 'Supplier country',
            name: 'country',
          },
        ]}
      />
    </Modal>
  );
}
