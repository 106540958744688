import { api } from '@app:services';
import { ActionContext } from '@app:types';
import { MaterialTypes } from '@ellure/types';
import { PaginationResponse } from '@services/api/endpoint';
import { BatchInputSchema, MaterialInputSchema, SupplierInputSchema, TemplateInputSchema } from 'components';
import { UseToastIntercept } from 'store';
import { StoreState } from 'store/store';
import { dateIsOlderThan } from 'utils';
import { ResourceMutations } from './resources.reducer';

const actions: ActionContext<StoreState, ResourceMutations> = {
  createResource: async function (
    _context,
    payload:
      | { resource: 'templates'; data: TemplateInputSchema }
      | { resource: 'materials'; data: MaterialInputSchema }
      | { resource: 'suppliers'; data: SupplierInputSchema },
  ) {
    try {
      switch (payload.resource) {
        case 'templates':
          await api.resources.templates.create({
            name: payload.data.name,
            specifications: {
              weight: payload.data.specificationsWeight,
              ingredients: payload.data.specificationsIngredients,
              feedstock: payload.data.specificationsFeedstock,
              forceManual: payload.data.options.forceManual,
            },
          });
          break;
        case 'materials':
          await api.resources.materials.create(payload.data);
          break;
        case 'suppliers':
          await api.resources.suppliers.create({
            ...payload.data,
            name: payload.data.name,
            countryCode: payload.data.country,
          });
          break;
      }
    } catch (e) {
      throw new UseToastIntercept({
        title: `Error creating ${payload.resource}.`,
        description: 'No new resource has been created.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  },
  fetchResources: async function (
    { mutate, rootState },
    payload: { resource: 'templates' | 'materials' | 'suppliers'; page?: number; limit?: number; forceRefresh?: boolean },
  ) {
    const resouceState = rootState.resources[payload.resource];
    const page = payload.page !== undefined ? payload.page : resouceState.page > -1 ? resouceState.page : 0;
    const limit = payload.limit !== undefined ? payload.limit : resouceState.limit;

    if (
      page == resouceState.page &&
      resouceState.limit >= limit &&
      !dateIsOlderThan(resouceState.lastUpdated, 3, 'm') &&
      !payload.forceRefresh
    )
      return;

    mutate(ResourceMutations.START_LOADING, payload.resource);
    try {
      let response: PaginationResponse<unknown>;
      switch (payload.resource) {
        case 'templates':
          response = await api.resources.templates.fetchList({ page, limit });
          break;
        case 'materials':
          response = await api.resources.materials.fetchList({ page, limit });
          break;
        case 'suppliers':
          response = await api.resources.suppliers.fetchList({ page, limit });
          break;
      }

      mutate(ResourceMutations.SET_RESOURCE_ITEMS, {
        itemSet: payload.resource,
        items: response.data,
        page: response.page,
        limit,
        totalItems: response.totalItems,
      });
    } catch (e) {
      throw new Error(`Error fetching ${payload.resource}.`);
    } finally {
      mutate(ResourceMutations.STOP_LOADING, payload.resource);
    }
  },
  updateResource: async function (
    _context,
    payload: { resourceId: string } & (
      | { resource: 'templates'; data: TemplateInputSchema }
      | { resource: 'materials'; data: MaterialInputSchema }
      | { resource: 'suppliers'; data: SupplierInputSchema }
    ),
  ) {
    try {
      switch (payload.resource) {
        case 'templates':
          await api.resources.templates.update({
            name: payload.data.name,
            specifications: {
              weight: payload.data.specificationsWeight,
              ingredients: payload.data.specificationsIngredients,
              feedstock: payload.data.specificationsFeedstock,
              forceManual: payload.data.options.forceManual,
            },
            templateId: payload.resourceId,
          });
          break;
        case 'materials':
          throw new Error(); //await api.resources.materials. .update({ body: payload.data, params: { materialId: payload.resourceId, }});
        case 'suppliers':
          await api.resources.suppliers.update({
            ...payload.data,
            name: payload.data.name,
            countryCode: payload.data.country,
            id: payload.resourceId,
          });
          break;
      }
    } catch (e) {
      throw new UseToastIntercept({
        title: `Error updating ${payload.resource}.`,
        description: 'Your action did not succeed.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  },
  createBatch: async function (
    { rootState },
    payload: {
      materialId: string;
      data: BatchInputSchema;
    },
  ) {
    try {
      await api.resources.materials.createBatch({
        ...payload.data,
        type: MaterialTypes.feedstock,
        materialId: payload.materialId,
        locationId: rootState.location.id,
      });
    } catch (e) {
      throw new UseToastIntercept({
        title: `Error creating batch.`,
        description: 'Your action did not succeed.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  },
};

export default actions;
